import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GET_POSTS_BY_CATEGORY } from '../graphql/queries'

import SEO from "components/seo"

import LatestBlog from 'components/latestBlog'

import BlogIndex from 'components/blogIndex'

export default () => {
    const data = useStaticQuery(graphql`
        query posts {
            siteFields {
                posts (first: 10000) {
                    edges {
                        node {
                            id
                            link
                            title
                            excerpt
                            date
                            featuredImage {
                                mediaItemUrl
                            }
                            resourceFields {
                                excerpt
                                fileOrLink 
                                file {
                                    mediaItemUrl
                                }
                                externalLink
                            }
                            postFields {
                                excerpt
                                heroImage {
                                    sourceUrl
                                }
                            }
                            categories {
                                nodes {
                                    name
                                    slug
                                }
                            }
                            tags {
                                nodes {
                                    name
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <>
            <SEO title='News & Insights' />
            <BlogIndex
                lazyQuery={GET_POSTS_BY_CATEGORY}
                initialItems={data.siteFields.posts.edges}
                itemsKey={'posts'}
                heroSection={(
                    <div className="LatestBlog">
                        <LatestBlog 
                            isHero={true}
                        />
                    </div>
                )}
            />
        </>
    )
}