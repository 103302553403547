import React, { useState, useMemo } from 'react'
import BlogGrid from "components/blogGrid"
import { useLazyQuery } from '@apollo/client'

export default ({
    lazyQuery,
    initialItems,
    heroSection,
    itemsKey,
    contentVisible: _contentVisible = true,
    filter = () => true
}) => {
    const [selectedCategorySlug, setSelectedCategorySlug] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [contentVisible, setContentVisible] = useState(true)

    const [getPostsByCategory, { data: postsData, loading: postsLoading }] = useLazyQuery(lazyQuery)

    const handleChangeCategory = slug => {
        setContentVisible(false)

        setTimeout(() => {
            setSelectedCategorySlug(slug)

            getPostsByCategory({
                variables: {
                    categoryName: slug
                },
            })
        },200)

        setTimeout(() => {
            setContentVisible(true)
        }, 300)
    }

    const posts = ((selectedCategorySlug && postsData ? postsData[itemsKey].edges : initialItems).map(post => post.node)).filter(filter)

    const filteredPosts = useMemo(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase()

        return posts.filter(({
            title,
            excerpt
        }) => {
            const lowerCaseTitle = title.toLowerCase()
            const lowerCaseExcerpt = excerpt.toLowerCase()

            return lowerCaseTitle.includes(lowerCaseSearchTerm) || lowerCaseExcerpt.includes(lowerCaseSearchTerm)
        })
    }, [searchTerm])

    const handleSort = slug => {
        setContentVisible(false)

        if (slug === 'newest') {

            posts.sort(function(a,b){
                return new Date(b.date) - new Date(a.date)
            })

        } else {

            posts.sort(function(a,b){
                return new Date(b.date) + new Date(a.date)
            })

        }

        setTimeout(() => {
            setContentVisible(true)
        }, 300)
    }

    const featuredIds = []

    const _posts = filteredPosts.filter(post => {
        return !~featuredIds.indexOf(post.id)
    })

    const blogGridCommonProps = {
        handleChangeCategory,
        handleSort,
        selectedCategorySlug,
        contentVisible: !_contentVisible ? false : contentVisible, // add a parent override where necessary
        postType: itemsKey,
        isIndex: true
    }

    return (
        <div className="blog">
            { heroSection || null }
            <BlogGrid 
                postType={itemsKey}
                posts={_posts} 
                postsLoading={postsLoading} 
                showFilter={true} 
                featuredIds={featuredIds}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                { ...blogGridCommonProps }
            />
        </div>
    )
}
