import React, { useState, useEffect, useRef } from "react"
import BlogGridFilter from "components/blogGridFilter"
import BlogGridPost from "components/blogGridPost"

import Button from 'components/button'

export default ({ 
    postType, 
    posts, 
    postsLoading, 
    showFilter, 
    selectedCategorySlug, 
    handleSort, 
    handleChangeCategory, 
    contentVisible,
    isIndex,
    searchTerm,
    setSearchTerm
}) => {
    let gridClassName = 'blog-grid'

    const increment = 6

    const [postsVisible, setPostsVisible] = useState(increment)
    const [listHeight, setListHeight] = useState(null)

    const listRef = useRef(null)

    const getListHeight = () => {
        return listRef.current ? listRef.current.getBoundingClientRect().height : null
    }

    useEffect(() => {
        if(listRef.current){
            // Just in case, for fonts loading etc
            setTimeout(() => {
                const _listHeight = getListHeight()

                setListHeight(_listHeight)
            },1000)
        }
    }, [listRef])

    if(!postsLoading && posts && contentVisible) {
        gridClassName += ' visible' 
    }

    if(isIndex){
        gridClassName += ' is-index'
    }

    if(!showFilter && !posts.length){
        return null
    }

    const _postsToRender = posts.slice(0, postsVisible)

    const handleLoadMore = () => {
        setPostsVisible(postsVisible + increment)

        setTimeout(() => {
            const _listHeight = getListHeight()

            setListHeight(_listHeight)
        },500)
    }

    return (
        <div>
            { showFilter ? 
                <BlogGridFilter 
                    postType={postType} 
                    selectedSlug={selectedCategorySlug} 
                    handleChange={handleChangeCategory} 
                    handleSort={handleSort} 
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                /> 
                : 
                <div className="blog-grid-empty"/>
            }
            <div className="container">
                <div 
                    className={gridClassName}
                    style={{
                        height: listHeight ? listHeight+'px' : undefined
                    }}
                >
                    <ul
                        ref={ref => listRef.current = ref}
                    >
                        { _postsToRender.length ?
                            _postsToRender.map(row => {
                                return (
                                    <BlogGridPost 
                                        postType={postType} 
                                        post={row} 
                                        key={row.link} 
                                    />
                                )
                            })
                        : 
                            showFilter ? `Sorry, there are no results${ searchTerm ? ' for this search term'  : ''}.` : null
                        }
                    </ul>
                </div>
                { (posts.length > postsVisible) ?
                    <div className="load-more-container">
                        <Button
                            label={'Load more posts'}
                            onClick={handleLoadMore}
                        />
                    </div>
                : null }
            </div>
        </div>
    )
}